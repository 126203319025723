import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

class BarGraph extends Component {
  render() {
    const rawPercentageVotes = Object.values(this.props.percentageVotes);
    const percentageVotes = rawPercentageVotes.map(
      (value) => Math.round(value * 10) / 10
    );

    const labels = Object.keys(this.props.percentageVotes);
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Percentage Votes",
          data: percentageVotes,
          backgroundColor: [
            "#FF0000",
            "#FF0000",
            "#FF0000",

            "#4372C4",
            "#4372C4",
            "#4372C4",
            "#00B050",
            "#00B050",
            "#00B050",
          ],
          borderColor: [
            "#FF0000",
            "#FF0000",
            "#FF0000",

            "#4372C4",
            "#4372C4",
            "#4372C4",
            "#00B050",
            "#00B050",
            "#00B050",
          ],
          // Add the rest of your styling here...
        },
      ],
    };

    const options = {
      aspectRatio: 1.3, // default value. Change it to the

      layout: {
        padding: {
          top: 25, // Adjust this value as needed to add padding at the top
        },
      },

      plugins: {
        legend: {
          display: false, // This will hide the legend
        },
        datalabels: {
          align: "end",
          anchor: "end",
          formatter: (value) => (value === 0 ? "" : `${value}%`), // Remove Math.round to show decimal
          color: "black",
        },
      },
      scales: {
        y: {
          display: false,
        },
        x: {
          beginAtZero: true,
          grid: {
            display: false, // Hide y-axis grid lines
          },
        },
      },
    };

    return (
      <Bar
        style={{ height: "100%", width: "100%", maxHeight: "50vw" }}
        data={data}
        options={options}
      />
    );
  }
}

export default BarGraph;
